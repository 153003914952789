import React, { useState } from 'react';
import VideoModal from '../VideoModal';
import videos from './videos';

const WhySection = () => {
  const [video, setVideo] = useState(null);
  return (
    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 items-start w-full mt-6 mx-2 md:mx-6 gap-4 max-w-screen-xl'>
      {videos.map(video => (
        <div key={video.id} className='overflow-hidden bg-[#313131] text-white flex flex-col w-full h-full flex-1 items-start justify-between justify-self-center items-self-start p-0 rounded-2xl max-w-[350px] lg:max-w-[280px]'>
          <div>
            <div className='w-full h-auto bg-white text-black'>
              <img src={video.thumbnail_url} className='w-full h-auto' />
            </div>
            <div className='px-4 pt-4'>
              <p className='text-left text-base font-bold'>{video.title}</p>
              {/* <p className='text-left text-xs mt-2'>
                  El voto puede ser una herramienta poderosa de transformación incluso en lugares donde no hay
                  democracia y donde el juego
                </p> */}
            </div>
          </div>
          <div className='w-full px-4 pb-4'>
            <button
              type='button'
              className='w-full mt-3 inline-flex items-center justify-center rounded-md border border-transparent bg-[#4B4A8C] px-4 py-2 text-sm font-medium text-white shadow-sm hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 uppercase'
              onClick={() => setVideo(video)}
            >
              Ver video
            </button>
          </div>
        </div>
      )
      )}
      <VideoModal video={video} setVideo={setVideo} />
    </div>
  );
}

export default WhySection;
