import React, { useState } from 'react';
import './index.css';
import SearchForm from './components/SearchForm';
import RegisterForm from './components/RegisterForm';
import WhySection from './components/WhySection';

import estadoLabLogo from '../src/images/estadoLabLogo.png';
import infografia from '../src/images/infografia.png';

import redesFb from '../src/images/redesFb.png';
import redesIg from '../src/images/redesIg.png';
import redesTw from '../src/images/redesTw.png';

const App = () => {
  const [selectedState, setSelectedState] = useState(null);
  const [address, setAddress] = useState(null);

  const getComponentByState = () => {
    let aux = '';
    if (!selectedState) {
      aux = (
        <>
          <div className='flex flex-1 flex-row justify-center items-center m-2'>
            <SearchForm setSelectedState={setSelectedState} setAddress={setAddress}/>
          </div>
          <div className='flex flex-1 flex-row justify-center items-center h-auto max-h-[500px] mt-3'>
            <img src={infografia} alt='INFOGRAFIA' className='w-auto h-full'/>
          </div>
        </>
      )
    } else {
      aux = (
        <>
          <div className='flex flex-1 m-2 justify-center items-center mb-3 md:mb-0'>
            <RegisterForm setSelectedState={setSelectedState} address={address}/>
          </div>
          <div className='flex flex-col m-7 flex-1 h-96 justify-center'>
            <p className='text-[#FFDC1E] text-5xl font-bold'>DEJANOS</p>
            <p className='text-[#FFDC1E] text-5xl font-bold'>TUS DATOS</p>
            <p className='text-[#FFDC1E] text-2xl font-bold'>PARA MANTENERTE INFORMADO</p>
            <p className='text-white text-xl'>
                de lugares de inscripción adicionales
              <br />
                que puedan abrirse en caso de que
              <br />
                el CNE habilite una jornada de inscripción
              <br />
                y actualizacion de RE de este año.
            </p>
          </div>
        </>

      )
    }
    return aux;
  }
  return (
    <div className='flex flex-col bg-gray-50'>
      {/* Header */}
      <header className='flex h-1/5 flex-col justify-center items-center md:flex-row py-3 md:py-5 mx-2'>
        <div className='flex flex-1 flex-col md:flex-row justify-center items-center w-full max-w-screen-xl'>
          <div className='flex flex-1 justify-start items-center'>
            <img src={estadoLabLogo} alt='ESTADO LAB' className='w-full max-w-[200px]'/>
          </div>
          <div className='flex flex-row gap-4 justify-end py-2 md:py-0'>
            <div className='flex flex-1 items-center'>
              <a href='#como'><p className='text-[#4B4A8C] text-xl font-extrabold'>¿CÓMO?</p></a>
            </div>
            <div className='flex flex-1 items-center'>
              <a href='#porque'><p className='text-[#4B4A8C] text-xl font-extrabold'>¿PORQUÉ?</p></a>
            </div>
            <div className='flex flex-1 items-center'>
              <a href='#enterate'><p className='text-[#4B4A8C] text-xl font-extrabold'>ENTÉRATE</p></a>
            </div>
          </div>
        </div>
      </header>
      {/* Formulario de busqueda y registro */}
      <div id='como' className='flex flex-row justify-center items-center bg-[#4b4a8c] min-h-[592px] bg-[url("../src/images/background.jpg")] bg-cover'>
        <div className='flex flex-col md:flex-row md:m-10 w-full max-w-screen-xl mt-2'>
          {getComponentByState()}
        </div>
      </div>
      {/* Textos */}
      <div className='flex flex-col m-7 items-center'>
        <div className='flex flex-col'>
          <div>
            <p className='text-xl font-extrabold tracking-widest'>
              La inscripción en el Registro Electoral en este momento se
              <br />
              hace en las Oficinas Regionales del CNE de cada Estado.
            </p>
          </div>

          <div className='mt-5'>
            <p className='text-lg font-bold'>
              ¿QUÉ NECESITAS?
            </p>
          </div>

          <div className='mt-2'>
            <p className='text-lg'>-Tu cédula de identidad laminada, vigente o vencida.</p>
            <p className='text-lg'>-Ir a la Oficina Regional del CNE más cercana.</p>
            <p className='text-lg'>-Horario de 8.30am a 3:00pm.</p>
          </div>

          <div className='mt-5'>
            <p className='text-lg'>
              En periodos electorales se habilitan jornadas de inscripción con puntos
              <br />
              especiales distribuidos por todo el país.
            </p>
          </div>

          <div className='mt-5'>
            <p className='text-lg'>
            Pero todavía no hay noticias de si se celebraran jornadas especiales en
              <br />
              este 2023 o en qué lugares se habilitarán los puntos.
            </p>
          </div>

          <div className='mt-4 border border-y-8 border-x-0 border-[#4B4A8C] p-2.5'>
            <p className='text-center text-lg  font-extrabold italic'>
              POR ESA RAZÓN ¡NO PODEMOS ESPERAR A LAS JORNADAS!
            </p>
            <p className='text-center text-3xl font-extrabold text-[#4B4A8C]'>
              ¡HAY QUE INSCRIBIRSE LO ANTES POSIBLE!
            </p>
          </div>

          <div className='mt-5'>
            <p className='text-lg font-bold'>
              ¿QUE MÁS NECESITAS?
            </p>
          </div>

          <div className='mt-2'>
            <p className='text-lg'>
              -Sacudirte la desesperanza aprendida que nos han inoculado quienes no quieren que nada cambie.
            </p>
            <p className='text-lg'>
              -Entender cómo el voto sí puede ser una herramienta de transformación incluso en contextos como el venezolano.</p>
            <p className='text-lg'>
              -Motivar a tus panas a inscribirse contigo.
            </p>
          </div>
        </div>
      </div>
      {/* Videos */}
      <div id='porque' className='flex flex-row items-center justify-center bg-videos mt-5'>
        <WhySection />
      </div>
      {/* Enterate */}
      <div id='enterate' className='flex flex-col bg-[#4b4a8c] md:flex-row justify-center items-center pt-14'>
        <div className='flex flex-col flex-1 m-3 md:flex-row md:m-10 w-full max-w-screen-xl px-4'>
          <div className='flex flex-1 justify-center items-center'>
            <RegisterForm />
          </div>
          <div className='flex flex-1 flex-col justify-center mt-4 md:mt-0 mx-6'>
            <p className='text-4xl text-[#FFDC1E] font-black'> #HablandoClaro </p>
            <p className='text-xl text-white italic'>Registrarte en el R.E no convierte el votar</p>
            <p className='text-xl text-white italic'>en un deber,</p>
            <p className='text-xl text-white font-light'>Pero coloca en tus manos la posibilidad</p>
            <p className='text-xl text-white font-light'>de alzar tu voz y decir “no estoy de</p>
            <p className='text-xl text-white font-light'>acuerdo”</p>
            <p className='text-[#FFDC1E] text-xl font-extrabold'>INSCRÍBETE EN FEBRERO Y</p>
            <p className='text-[#FFDC1E] text-xl font-extrabold'>CANCELA EL FEBRERO REBELDE</p>
          </div>
        </div>
      </div>
      {/* Redes sociales */}
      <div className='flex justify-center items-end bg-[url("../src/images/bg-rrss.png")] bg-cover min-h-[200px] bg-[#4B4A8C] pb-8'>
        <div className='flex flex-1 flex-row justify-center gap-x-4'>
          <div>
            <a href='https://www.facebook.com/100083022198237' target='_blank' rel='noreferrer'><img src={redesFb} alt='Facebook' className='w-12'/></a>
          </div>
          <div>
            <a href='https://instagram.com/estadolab' target='_blank' rel='noreferrer'><img src={redesIg} alt='Instagram' className='w-12'/></a>
          </div>
          <div>
            <a href='https://twitter.com/estadolab' target='_blank' rel='noreferrer'><img src={redesTw} alt='Twitter' className='w-12'/></a>
          </div>
        </div>
      </div>

    </div>
  );
}

export default App;

