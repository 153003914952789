const videos = [
  {
    'id': '__5F2HDr7os',
    'title': 'Hablando Claro: ¿Qué opinan los jóvenes sobre el Registro Electoral?',
    'author_name': 'Estado Lab ',
    'author_url': 'https://www.youtube.com/@estadolab',
    'type': 'video',
    'height': 113,
    'width': 200,
    'version': '1.0',
    'provider_name': 'YouTube',
    'provider_url': 'https://www.youtube.com/',
    'thumbnail_height': 360,
    'thumbnail_width': 480,
    'thumbnail_url': 'https://i.ytimg.com/vi/__5F2HDr7os/hqdefault.jpg',
    'html': '<iframe width="200" height="113" src="https://www.youtube.com/embed/__5F2HDr7os?feature=oembed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen title="Hablando Claro: ¿Qué opinan los jóvenes sobre el Registro Electoral?"></iframe>'
  },
  {
    'id': 'q4W3yWyNNd4',
    'title': 'Hablando Claro: ¿Por qué es importante inscribirse en el RE?',
    'author_name': 'Estado Lab ',
    'author_url': 'https://www.youtube.com/@estadolab',
    'type': 'video',
    'height': 113,
    'width': 200,
    'version': '1.0',
    'provider_name': 'YouTube',
    'provider_url': 'https://www.youtube.com/',
    'thumbnail_height': 360,
    'thumbnail_width': 480,
    'thumbnail_url': 'https://i.ytimg.com/vi/q4W3yWyNNd4/hqdefault.jpg',
    'html': '<iframe width="200" height="113" src="https://www.youtube.com/embed/q4W3yWyNNd4?feature=oembed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen title="Hablando Claro: ¿Por qué es importante inscribirse en el RE?"></iframe>'
  },
  {
    'id': '-O73jDmneLo',
    'title': 'Hablando Claro: ¿Dictadura "no" sale con votos?',
    'author_name': 'Estado Lab ',
    'author_url': 'https://www.youtube.com/@estadolab',
    'type': 'video',
    'height': 113,
    'width': 200,
    'version': '1.0',
    'provider_name': 'YouTube',
    'provider_url': 'https://www.youtube.com/',
    'thumbnail_height': 360,
    'thumbnail_width': 480,
    'thumbnail_url': 'https://i.ytimg.com/vi/-O73jDmneLo/hqdefault.jpg',
    'html': '<iframe width="200" height="113" src="https://www.youtube.com/embed/-O73jDmneLo?feature=oembed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen title="Hablando Claro: ¿Dictadura &quot;no&quot; sale con votos?"></iframe>'
  },
  {
    'id': 'r_uiFGNT8Os',
    'title': 'Hablando Claro: ¿Es posible la transición a la democracia a través del voto?',
    'author_name': 'Estado Lab ',
    'author_url': 'https://www.youtube.com/@estadolab',
    'type': 'video',
    'height': 113,
    'width': 200,
    'version': '1.0',
    'provider_name': 'YouTube',
    'provider_url': 'https://www.youtube.com/',
    'thumbnail_height': 360,
    'thumbnail_width': 480,
    'thumbnail_url': 'https://i.ytimg.com/vi/r_uiFGNT8Os/hqdefault.jpg',
    'html': '<iframe width="200" height="113" src="https://www.youtube.com/embed/r_uiFGNT8Os?feature=oembed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen title="Hablando Claro: ¿Es posible la transición a la democracia a través del voto?"></iframe>'
  }
];

export default videos;
