const ore = [
  {
    messageNum: '20',
    edo: '1',
    estado: 'DISTRITO CAPITAL',
    municipio: 'LIBERTADOR',
    direccion: 'AV. COLÓN, GALPÓN SANDIO, LOCAL CNE, DETRÁS DEL CNE DE PLAZA VENEZUELA, MARIPÉREZ, CARACAS.',
    telefono: '0212-8087527 / 0212-5727999',
    mapLink: 'https://goo.gl/maps/qiqYsW2UA51VH9er6'
  },
  {
    messageNum: '21',
    edo: '22',
    estado: 'AMAZONAS',
    municipio: 'ATURES',
    direccion: 'AV. MELICIO PÉREZ, DIAGONAL AL MINISTERIO PÚBLICO, EDIF. CNE, PUERTO AYACUCHO.',
    telefono: '0248-5210065 / 0248-5215522',
    mapLink: 'https://goo.gl/maps/YkKW93w2qQcnPi6H9'
  },
  {
    messageNum: '22',
    edo: '2',
    estado: 'ANZOATEGUI',
    municipio: 'BOLIVAR',
    direccion: 'AV PRINCIPAL BOYACÁ, CASA CNE, BARCELONA',
    telefono: '0281-2710975',
    mapLink: 'https://goo.gl/maps/xigs2njxSXbNxfGu8'
  },
  {
    messageNum: '23',
    edo: '3',
    estado: 'APURE',
    municipio: 'SAN FERNANDO',
    direccion: 'CALLE BARINAS, Nº 24, EDIF. CNE, SECTOR SAMÁN LLORÓN, DETRÁS DE LA ESTACIÓN DE SERVICIO LLANO II. SAN FERNANDO DE APURE.',
    telefono: '0247-3414936',
    mapLink: 'https://goo.gl/maps/ubyHwzkx7KV9yGuT8'
  },
  {
    messageNum: '24',
    edo: '4',
    estado: 'ARAGUA',
    municipio: 'GIRARDOT',
    direccion: 'ZONA INDUSTRIAL SAN JACINTO, 1ERA AV., DIAGONAL A LA UNIV. SANTIAGO MARIÑO, EDIFICIO SEDE CNE. MARACAY.',
    telefono: '0243-2340339',
    mapLink: 'https://goo.gl/maps/ghRjptLKfCHZ3TxQA'
  },
  {
    messageNum: '25',
    edo: '5',
    estado: 'BARINAS',
    municipio: 'BARINAS',
    direccion: 'AV.CUATRICENARIA, ZONA 33',
    telefono: '0273-5460056',
    mapLink: 'https://goo.gl/maps/17MC1XEDKFez2L8E6'
  },
  {
    messageNum: '26',
    edo: '6',
    estado: 'BOLIVAR',
    municipio: 'HERES',
    direccion: 'AV. TACHIRA, QUINTA IGNORGA, N.- 50, CIUDAD BOLIVAR',
    telefono: '0285-6324195',
    mapLink: 'https://goo.gl/maps/t2tNDHp34hkGyMWi8'
  },
  {
    messageNum: '27',
    edo: '7',
    estado: 'CARABOBO',
    municipio: 'VALENCIA',
    direccion: 'FINAL AVENIDA MONTES DE OCA, ENTRE CALLE EL LICEO Y AGUA BLANCA. SECTOR PADRE ALFONSO. FRENTE A RESIDENCIAS LA ARBOLEDA. MUNICIPIO VALENCIA. PARROQUIA SAN JOSÉ',
    telefono: '0241-8213661',
    mapLink: 'https://goo.gl/maps/w5ka9he5PzJF93SE6'
  },
  {
    messageNum: '28',
    edo: '8',
    estado: 'COJEDES',
    municipio: 'EZEQUIEL ZAMORA',
    direccion: 'CALLE MADARIAGA, ENTRE CALLE AYACUCHO Y CALLE CARABOBO, EDIF. JOSÉ ANTONIO PÁEZ VIOLANTE, PISO 1, SAN CARLOS.',
    telefono: '0258-4331661',
    mapLink: 'https://goo.gl/maps/9JK8UVvygXxyNWa7A'
  },
  {
    messageNum: '29',
    edo: '23',
    estado: 'DELTA AMAC',
    municipio: 'TUCUPITA',
    direccion: 'PASEO MÁNAMO, ENTRE CALLES DELTA Y AMACURO, ANTIGUO HOSPITAL, TUCUPITA.',
    telefono: '0287-7212803 / 0287-7212755',
    mapLink: 'https://goo.gl/maps/MwqffppfUpYQWmdm8'
  },
  {
    messageNum: '30',
    edo: '9',
    estado: 'FALCON',
    municipio: 'MIRANDA',
    direccion: 'CALLE MIRANDA ENTRE CALLES TOLEDO Y AV. MANAURE, CERCA DEL HOTEL URUMACO, CORO',
    telefono: '0268-2511879 / 0268-2522102',
    mapLink: 'https://goo.gl/maps/G8ncLx4HUjmo3u576'
  },
  {
    messageNum: '31',
    edo: '10',
    estado: 'GUARICO',
    municipio: 'ROSCIO',
    direccion: 'QUINTA BETICA, CALLE ROSCIO, ENTRE CALLE PÁEZ Y SALIAS, SAN JUAN DE LOS MORROS DIAGONAL A INTERCABLE',
    telefono: '0246-4310034',
    mapLink: 'https://goo.gl/maps/9738EXez5qaZbaNq5'
  },
  {
    messageNum: '32',
    edo: '24',
    estado: 'LA GUAIRA',
    municipio: 'VARGAS',
    direccion: 'AV. SOUBLETTE CON CALLE BOLIVAR, EDIFICIO IPOSTEL, PISO 1',
    telefono: '0212-3318927 / 0212-3318909',
    mapLink: 'https://goo.gl/maps/WB6AFM6C5Ymhg7JZ6'
  },
  {
    messageNum: '33',
    edo: '11',
    estado: 'LARA',
    municipio: 'IRIBARREN',
    direccion: '\tCARRERA 24 C/CALLE 6, URB. DEL ESTE. Nº 9-61, FRENTE A LA GASTROCLÍNICA, BARQUISIMETO.',
    telefono: '0251-2623530 / 0251-2516077',
    mapLink: 'https://goo.gl/maps/3gHb2BTgeWW8hV33A'
  },
  {
    messageNum: '34',
    edo: '12',
    estado: 'MERIDA',
    municipio: 'MERIDA',
    direccion: 'CALLE 44 CON AVENIDAS URDANETA Y GONZALO PICON, SECTOR GONZALO PICON, CASA 3-79, PARROQUIA EL LLANO, MUNICIPIO LIBERTADOR, ESTADO MÉRIDA',
    telefono: '0274-2631319',
    mapLink: 'https://goo.gl/maps/x1R5MxUUgs6DiqSg6'
  },
  {
    messageNum: '35',
    edo: '13',
    estado: 'MIRANDA',
    municipio: 'GUAICAIPURO',
    direccion: 'SEDE 1: EDF. CANAIMA. PISO 1, CALLE GUAICAIPURO DIAGONAL A LA PLAZA BOLÍVAR. LOS TEQUES.\r\n' +
        'SEDE 2: RESIDENCIAS CARACAS, NIVEL MZZ 2, LOCAL 28, FINAL AV. BOLÍVAR, LOS TEQUES. SEDE3: AV. VÍCTOR BAPTISTA, SECTOR LA INDIA, EDF.MODELO, PISO 3 VÍA ELPASO . LOS TEQUES.',
    telefono: '0212-3214873 / 0212-3642811 / 0212-3233698',
    mapLink: 'https://goo.gl/maps/NNvDtPdS4LZYqstF9'
  },
  {
    messageNum: '36',
    edo: '14',
    estado: 'MONAGAS',
    municipio: 'MATURIN',
    direccion: 'CALLE BERMÚDEZ,SECTOR MERCADO VIEJO, CENTRO COMERCIAL FUNDEMOS, TORRE SUR, PISO 3, MATURIN',
    telefono: '0291-641.39.27',
    mapLink: 'https://goo.gl/maps/ux4ZYLEf23dxJ5n9A'
  },
  {
    messageNum: '37',
    edo: '15',
    estado: 'NVA.ESPARTA',
    municipio: 'ARISMENDI',
    direccion: 'CALLE RUÍZ, QUINTA PIANANA, N- 2-147, A 500 MTS DEL CRUCERO DE GUACUCO, LA ASUNCIÓN',
    telefono: '0295-2421221 / 0295-2424122',
    mapLink: 'https://goo.gl/maps/7CSGqhq1n2M4reEF7'
  },
  {
    messageNum: '38',
    edo: '16',
    estado: 'PORTUGUESA',
    municipio: 'GUANARE',
    direccion: 'CARRERA 15 CON ESQUINA ALLE 11, EDIFICIO TELECOMUNICACIONES PISO 1',
    telefono: '0257-2511044 / 0257-2533003',
    mapLink: 'https://goo.gl/maps/BEFV8ssuS19HGsTT8'
  },
  {
    messageNum: '39',
    edo: '17',
    estado: 'SUCRE',
    municipio: 'SUCRE',
    direccion: 'AV. CANCAMURE, Nº 13-48, AL LADO DE LA OBRAS PÚBLICAS CUMANÁ, FRENTE AL POLIDEPORTIVO, PARROQUIA ALTAGRACIA, CUMANÁ.',
    telefono: '0293-4522809 / 0416-6058553',
    mapLink: 'https://goo.gl/maps/69Y9wsKSd484nT488'
  },
  {
    messageNum: '40',
    edo: '18',
    estado: 'TACHIRA',
    municipio: 'SAN CRISTOBAL',
    direccion: 'CALLE 14 ENTRE CARRERAS 22 Y 23, SECTOR BARRIO OBRERO. FRENTE A LA FARMACIA MIKEL, QUINTA ADRIANITA SAN CRISTOBAL, TACHIRA',
    telefono: '0276-3434357 / 0276-3533176',
    mapLink: 'https://goo.gl/maps/59h5eWzqme2mfsCE6'
  },
  {
    messageNum: '41',
    edo: '19',
    estado: 'TRUJILLO',
    municipio: 'TRUJILLO',
    direccion: 'SECTOR STA ROSA, QTA SAN JOSÉ, AV. MENDOZA ENTRE CALLES BATALLA CAMPAL DE NIQUITAO Y CONTICICINIO, TRUJILLO,',
    telefono: '0272-2366360 / 0272-2366103',
    mapLink: 'https://goo.gl/maps/LauEsuZN5Nk1Pk3a7'
  },
  {
    messageNum: '42',
    edo: '20',
    estado: 'YARACUY',
    municipio: 'SAN FELIPE',
    direccion: '\tAV. YARACUY, ENTRE AV. CEDEÑO Y AV. ALBERTO RAVEL, QUINTA VIOLETA, SAN FELIPE.',
    telefono: '0254-2310772 / 0254-2310244',
    mapLink: 'https://goo.gl/maps/JALBeKH4pjCerHTR6'
  },
  {
    messageNum: '43',
    edo: '21',
    estado: 'ZULIA',
    municipio: 'MARACAIBO',
    direccion: 'AV. EL MILAGRO NORTE, CIRCUNSCRIPCIÓN DE ALISTAMIENTO MILITAR LA BARRACA AL LADO DEL HOSPITAL REGULO PACHANO AÑEZ (SANIPEZ)',
    telefono: '0261-7324225 / 0261-7324202',
    mapLink: 'https://goo.gl/maps/HUkqwfdyioXmp9S17'
  }
];

export default ore;
