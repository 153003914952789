import React from 'react';
import { useForm } from 'react-hook-form';
import ore from '../../data/ore';

const SearchForm = ({ setSelectedState, setAddress })=>{
  const {
    register,
    handleSubmit,
    /*     formState: { errors } */
  } = useForm({
    defaultValues: {}
  });

  const onSubmit = dataForm => {
    const { stateId } = dataForm;
    setSelectedState(stateId);
    console.log(stateId);
    let selectedOre = ore.filter(item => item.edo === stateId);
    let address = '';
    if (selectedOre.length > 0) {
      selectedOre = selectedOre[0];
      address = `Esta es la oficina del estado ${selectedOre.estado}: \n Municipio ${selectedOre.municipio}, \n${selectedOre.direccion}\n📞 ${selectedOre.telefono}\n\n📍 ${selectedOre.mapLink}`;
      const phoneList = selectedOre.telefono.split('/').map(currentPhone => {
        const hrefPhone = currentPhone.trim().replace('-', '');
        return <><a key={hrefPhone} href={`tel:${hrefPhone}`} rel='noreferrer' className='text-[#4B4A8C]'>{currentPhone}</a>{' '}</>;
      });
      address = (<div className='text-gray-500 text-base'>
        <p className=''><span className='font-bold'>{selectedOre.estado}, </span>{selectedOre.municipio}</p>
        <p>{selectedOre.direccion}</p>
        <p>📞 {phoneList}</p>
        <p>📍 <a href={selectedOre.mapLink} target='_blank' rel='noreferrer' className='text-[#4B4A8C] underline'>{selectedOre.mapLink}</a></p>
      </div>);
      setAddress(address);
    }
  }

  const oreList = ore.map(item =>
    <option key={item.edo} value={item.edo}>{item.estado}</option>
  );

  return (
    <div className='bg-white px-4 py-4 md:px-8 md:py-8 rounded-2xl w-full max-w-[500px]'>
      <div className='mb-3'>
        <p className='text-[#4B4A8C] text-2xl font-extrabold'>CONSULTA EN DONDE <br/>PUEDES INSCRIBIRTE</p>
      </div>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <select
              id='stateId'
              name='stateId'
              {...register('stateId', { required: true })}
              className='border-gray-100 border mt-1 block w-full h-10 rounded-md px-4 text-base sm:text-sm bg-gray-50 text-gray-600'
            >
              <option value=''>Seleccione un estado</option>
              {oreList}
            </select>
            <div className='flex flex-row mt-3'>
              <button
                id='searchAddressButton'
                type='submit'
                className='inline-flex items-center justify-center px-3 py-1.5 border border-transparent text-md rounded shadow-sm text-white bg-[#4B4A8C] w-24 text-base font-extrabold'
              >
                BUSCAR
              </button>
            </div>
          </div>
        </form>

      </div>
    </div>
  )
}

export default SearchForm;
