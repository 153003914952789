import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'

const VideoModal = props => {
  const { video, setVideo } = props;
  const open = !!video;
  const close = () => setVideo(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-90 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='w-[90vw] md:w-[800px] md:h-[500px] relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full max-w-[90vw] md:max-w-[800px]}'>
                <button type='button' onClick={() => close()} className='absolute top-[-15px] right-[-15px] bg-white rounded-full p-2 text-gray-900 hover:text-gray-400'>
                  <span className='sr-only'>Close</span>
                  <svg className='h-6 w-6' fill='none' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor' aria-hidden='true'>
                    <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
                  </svg>
                </button>
                {video && (
                  <div className='overflow-hidden flex justify-center w-full h-full md:w-[800px] md:h-[500px] rounded-lg'>
                    <iframe
                      className='sm:w-full xs:h-auto'
                      onLoad={'Cargando...'}
                      loading='lazy'
                      width='800'
                      height='500'
                      src={`https://www.youtube.com/embed/${video.id}?feature=oembed`}
                      title='YouTube video player'
                      frameBorder='0'
                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                      allowfullscreen
                    />
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default VideoModal;
