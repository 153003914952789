import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import * as api from '../../api/index';
import Spinner from '../Spinner';
import PhoneInput from '../PhoneInput';

const RegisterForm = ({ setSelectedState = null, address = '' })=>{

  //const currentCountryCode = watch('countryCode', false);
  const [showAllFields, setShowAllFields] = useState(false);
  const [loading, setLoading] = useState(false);
  const [signUpCompleted, setSignUpCompleted] = useState(false);
  const [error, setError] = useState(false);

  const myCustomJoi = Joi.extend(require('joi-phone-number'));

  const schema = Joi.object({
    ci: myCustomJoi.string().allow('').max(255)
      .messages({
        'string.base': 'Campo requerido.', // para los demas casos
        'string.empty': 'Campo requerido.',
        'any.required': 'Campo requerido.',
        'string.min': 'Ingresa un valor válido.',
        'string.max': 'Ingresa un valor válido.',
      }),
    email: myCustomJoi.string().email({ tlds: { allow: false } }).required()
      .messages({
        'string.base': 'Campo requerido.', // para los demas casos
        'string.empty': 'Campo requerido.',
        'any.required': 'Campo requerido.',
        'string.email': 'Ingresa un correo válido.'
      }),
    phone: myCustomJoi.string().allow('').max(15).phoneNumber()
      .messages({
        'string.base': 'Campo requerido.', // para los demas casos
        'any.required': 'Campo requerido.',
        'string.empty': 'Campo requerido.',
        'string.min': 'Ingresa un teléfono válido.',
        'string.max': 'Ingresa un teléfono válido.',
        'phoneNumber.invalid': 'Ingresa un teléfono válido.'
      })
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {},
    resolver: joiResolver(schema)
  });

  const onSubmit = async data => {
    console.log(data);
    setLoading(true);
    try {
      await api.signUp(data);
      setSignUpCompleted(true);
      setError(false);
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!signUpCompleted) {
      reset({ ci: '', email: '', phone: '58' });
    }
  }, [signUpCompleted]);

  const sendButtonText = loading ? <Spinner /> : 'REGÍSTRATE';

  return (
    <>
      <div className='bg-white px-4 py-4 md:px-8 md:py-8 rounded-2xl w-full max-w-[500px]'>
        {!signUpCompleted && (<div>
          {address && (<div className='mb-6'>
            <p className='mb-2'><a href='#' onClick={() => setSelectedState(null)} className='text-[#4B4A8C] text-base'>⬅️ Buscar otro Estado</a></p>
            <p className='text-[#4B4A8C] text-xl font-extrabold mb-2'>INSCRÍBETE EN</p>
            {address}
          </div>)}
        </div>)}
        {!signUpCompleted && (<div>
          {!address && <p className='text-[#4B4A8C] text-2xl font-extrabold mb-3'>ENTÉRATE</p>}
          {address && <p className='text-[#4B4A8C] text-base font-extrabold mb-2'>REGÍSTRATE Y MANTENTE INFORMADO</p>}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className='mb-2'>
                <input id='email' {...register('email')} onFocus={() => setShowAllFields(true)} className='mb-0 rounded-md border-gray-100 border h-10  placeholder:text-gray-500 px-4 w-full bg-gray-100' placeholder='Correo electrónico'/>
                {errors.email && (<p className='text-sm text-red-600'>{errors['email'].message}</p>)}
              </div>
              {showAllFields && (
                <>
                  <div className='mb-2'>
                    <p className='text-start text-xs text-gray-500'>Campos opcionales</p>
                    <input id='ci' {...register('ci')} className='mb-0 rounded-md border-gray-100 border h-10  placeholder:text-gray-500  px-4 w-full bg-gray-100' maxLength={255} placeholder='Nombre o cédula' />
                    {errors.ci && (<p className='text-sm text-red-600'>{errors['ci'].message}</p>)}
                  </div>
                  <div className='mb-2'>
                    <PhoneInput id='phone' name='phone' control={control} placeholder='Teléfono'/>
                    {errors.phone && (<p className='text-sm text-red-600'>{errors['phone'].message}</p>)}
                  </div>
                </>
              )}
              <div className='flex flex-col mt-3'>
                <button
                  id='signUpButton'
                  type='submit'
                  className='min-w-[150px] h-[40px] inline-flex items-center justify-center px-3 py-1.5 border border-transparent text-base rounded-md shadow-sm text-white bg-[#4B4A8C] w-auto text-md font-extrabold'
                >
                  {sendButtonText}
                </button>
                {error && (<p className='text-sm text-red-600'>Por favor, vuelva a intentarlo.</p>)}
              </div>
            </div>
          </form>
        </div>)}
        {signUpCompleted && (
          <div>
            <p className='text-gray-600 text-xl font-extrabold mb-2'>Gracias por registrarte.</p>
            <p className='mb-2'><button type='button' onClick={() => setSignUpCompleted(false)} className='text-[#4B4A8C] text-base'>⬅️ Volver al registro</button></p>
          </div>
        )}
      </div>
    </>
  )
}

export default RegisterForm;
