import React from 'react';
import 'react-phone-input-2/lib/bootstrap.css';
import ReactPhoneInput from 'react-phone-input-2';
import { Controller } from 'react-hook-form';

const PhoneInput = props => {
  const { ipCountry = 've', isDisabled = false, name, control, current } = props;
  return (
    <>
      <Controller
        control={control}
        name='phone'
        rules={{ required: true }}
        render={({ field: { ref, ...field } }) => (
          <ReactPhoneInput
            {...field}
            inputProps={{
              id: 'phone-input',
              className: 'mb-0 rounded-md border-gray-100 border h-10 placeholder:text-gray-500 pl-14 w-full bg-gray-100'
            }}
            inputExtraProps={{
              ref,
              required: true,
              autoFocus: true
            }}
            country={ipCountry || 've'}
            countryCodeEditable={false}
            masks={{ ve: '(...) ...-..-..' }}
          />
        )}
      />
    </>
  );
};

export default PhoneInput;
